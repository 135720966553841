<template>
    <div class="tree-item">
        <tree-header-doc
            v-if="dataChildrenAndLength"
            :data="data"
            :isIndex="isIndex"
            :isDragged="isDragged"
            :isMaterial="isMaterial"
            @change-dragged="$emit('change-dragged', $event)"
            style="margin-left: 20px;"
        />

        <dropdown
            v-else
            style="padding-left: 20px;"
            active-class="sidebar-tree__wrap--opened"
            :opened="opened"
            :key="data.name ? `${data.name}_${data.id}_${data.active}` : `${data.title}_${data.id}_${data.active}`"
            :styles="{
                transformOrigin: 'top',
                transition: 'transform .4s ease-in-out'
            }"
            animation="slide"
        >
            <template v-slot:header>
                <tree-header-doc
                    :data="data"
                    :isIndex="isIndex"
                    :isDragged="isDragged"
                    :isMaterial="isMaterial"
                    @toggleDropdown="toggleDropdown"
                    @change-dragged="$emit('change-dragged', $event)"
                />
            </template>
            <template v-slot:body>
                <div class="sidebar__nav-dropdown">
                    <template>
                        <tree-item-doc
                            v-for="(variable, index) in itemsArray"
                            :key="variable.title ? `${variable.title}_${variable.id}` : `itemDoc_${variable.id}`"
                            :data="variable"
                            :isIndex="index"
                            :isMaterial="(variable.is_href) ? true : false"
                            :isDragged="isDragged"
                            @change-dragged="$emit('change-dragged', $event)"
                        />
                    </template>
                </div>
            </template>
        </dropdown>
    </div>
</template>

<script>
import TreeHeaderDoc from '@/components/sidebar/tree/TreeHeaderDoc'
import Dropdown from '@/components/Dropdown'

export default {
    name: 'TreeItemDoc',
    components: { Dropdown, TreeHeaderDoc },
    data() {
        return {
            opened: false,
            itemsArray: []
        }
    },
    props: {
        data: Object,
        isMaterial: Boolean,
        isDragged: Boolean,
        isIndex: Number
    },
    methods: {
        toggleDropdown() {
            this.opened = !this.opened
        },

        initItemsArray() {
            const childrenArray = this.data.children || []
            const listArray = this.data.list || []

            this.itemsArray = childrenArray.concat(listArray)
            this.itemsArray = this.itemsArray.map((variable) => {
                variable.parent_id = (variable.parent_id !== undefined) ? variable.parent_id : this.data.id;
                return variable
            })
        }
    },
    computed: {
        dataChildrenAndLength() {
            return !this.data.children?.length && !this.data.list?.length
        }
    },
    created() {
        this.initItemsArray()
    },
    updated() {
        this.initItemsArray()
        this.opened = true
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.tree-item {
    /*&:hover {
        background-color: #616575 !important;
        border-radius: 4px;
    }*/
}
</style>
